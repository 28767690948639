export default async function addToList(
  product: any,
  email: string | null,
  listId: string,
  locale: string
) {
  const listItem = [{
    handle: product?.slug, title: product?.name, data: {
      productType: product?.productType,
      metafields: product?.metafields,
      colourCode: product?.colourCode,
      colourCardHandle: product?.colourCardHandle,
      path: product?.path,
      image: {
        url: product?.images?.[0]?.url,
        altText: product?.images?.[0]?.altText,
      }
    }
  }]
  let success: boolean = false
  try {
    const response = await fetch(`/api/list/${listId}/items`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, listItem, locale }),
    })
    if (response.status === 200) {
      success = true
    }
  } catch (err) {
    console.log(err)
  }
  return success
}
