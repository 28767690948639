import { Heart, SpinLoader } from '@components/icons'
import { useUI } from '@components/ui'
import useGetList from '@lib/arkade/list/hooks/useGetList'
import useGetLists from '@lib/arkade/list/hooks/useGetLists'
import { List } from '@lib/arkade/list/types'
import addToList from '@lib/arkade/list/utils/addToList'
import removeFromList from '@lib/arkade/list/utils/removeFromList'
import cn from 'clsx'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useSWRConfig } from 'swr'
import { useArkadeList } from '../context'
interface WishlistButtonProps {
  listId?: string | null
  product: any
  smallestCard?: boolean
  isPDP?: boolean
}
export default function WishlistButton({
  listId,
  product,
  smallestCard,
  isPDP,
}: WishlistButtonProps) {
  const { locale } = useRouter()
  const { t } = useTranslation('common')
  const {
    tempListId: localTempListId,
    userTempListId,
    listOwnerEmail,
  } = useArkadeList()
  const tempListId = userTempListId || localTempListId
  const { data: userLists } = useGetLists(listOwnerEmail, locale)
  const { data: list } = useGetList(listId!, locale)
  const { data: tempList } = useGetList(tempListId, locale)
  const [hover, setHover] = useState(false)
  const [isFavourite, setIsFavourite] = useState(false)
  const [loading, setLoading] = useState(false)
  const {
    closeCartNotification,
    openCartNotification,
    setAddToListProduct,
    setSidebarView,
    openSidebar,
  } = useUI()
  const { mutate } = useSWRConfig()
  const { slug, handle } = product
  const checkIsFavourited = (lists: List[], slugOrHandle: string) => {
    if (!Array.isArray(lists)) return false
    return lists?.some((list) =>
      list.listItem?.some((item) => item.handle === slugOrHandle)
    )
  }
  useEffect(() => {
    !listOwnerEmail &&
      setIsFavourite(checkIsFavourited([tempList], handle || slug))
  }, [handle, listOwnerEmail, slug, tempList])
  useEffect(() => {
    listOwnerEmail &&
      setIsFavourite(checkIsFavourited(userLists, handle || slug))
  }, [handle, listOwnerEmail, slug, userLists])
  const handleMouseOver = () => {
    !isMobile && setHover(true)
  }
  const handleMouseLeave = () => {
    !isMobile && setHover(false)
  }

  const toggleFavourite = async (
    id: string,
    listName: string,
    tempList?: boolean
  ) => {
    setLoading(true)
    try {
      const res = isFavourite
        ? tempList
          ? await removeFromList(product, null, tempListId, locale!)
          : await removeFromList(product, listOwnerEmail, id, locale!)
        : tempList
        ? await addToList(product, null, tempListId, locale!)
        : await addToList(product, listOwnerEmail, id, locale!)
      const cartNotification = isFavourite
        ? `${product?.name || product?.title} ${
            listId
              ? `${t('removed-from')} ${listName}`
              : `${t('removed-from-favourites')}`
          }`
        : `${product?.name || product?.title}  ${
            listId
              ? `${t('added-to')} ${listName}`
              : `${t('added-to-your-favourites')}`
          }`
      if (res) {
        setLoading(false)
        closeCartNotification()
        openCartNotification(cartNotification)
        setIsFavourite((favourite) => !favourite)
        mutate(`/api/list/${tempListId || id}?locale=${locale}`)
      } else {
        setLoading(false)
        throw new Error(`${t('issue-saving-favourites-try-later')}`)
      }
    } catch (error) {
      setLoading(false)
      closeCartNotification()
      openCartNotification(`${t('issue-saving-favourites-try-later')}`)
      mutate(`/api/list/${tempListId || id}?locale=${locale}`)
    }
  }
  const handleTempListAction = async () => {
    //Handle favourite for [tempList]
    await toggleFavourite(tempListId, tempList?.name || t('Favourites'), true)
  }
  const handleUserListAction = async () => {
    //Handle favourite for [listId]
    await toggleFavourite(list.id, list.name || t('Favourites'))
  }
  const handleSideBarAction = () => {
    //Handle favourite from sidebar
    setAddToListProduct(product)
    setSidebarView('ADD_TO_LIST_SIDEBAR_VIEW')
    openSidebar()
  }
  const handleClick = (e: any) => {
    e.preventDefault()
    const action = listOwnerEmail
      ? listId
        ? handleUserListAction
        : handleSideBarAction
      : handleTempListAction
    action()
  }
  return (
    <div
      onClick={handleClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseLeave}
    >
      <div
        className={cn(
          'flex cursor-pointer items-center justify-center rounded-sm opacity-75',
          {
            'bg-white': hover && !smallestCard,
            'gap-2': !isFavourite,
            'h-8 pl-3 pr-1': !isPDP,
            'h-12 w-12': isPDP,
          }
        )}
      >
        {hover && !smallestCard && !isPDP && (
          <div className="label mr-1 text-dark-grey">
            {listOwnerEmail
              ? isFavourite
                ? `${t('added-to-list')}`
                : `${t('add-to-list')}`
              : isFavourite
              ? `${t('added-to-temp-list')}`
              : `${t('add-to-temp-list')}`}
          </div>
        )}
        {!loading && (
          <div
            className={cn('flex items-center justify-center', {
              '-m-1 h-8 w-8 rounded-full bg-white':
                ((smallestCard && hover) || isFavourite) && !isPDP,
              'h-12 w-12 rounded-full border border-ui-light-grey transition-all duration-300 ease-in-out hover:bg-ui-lightest-grey':
                isPDP,
            })}
          >
            {isPDP ? (
              isFavourite ? (
                <Heart stroke="grey" />
              ) : (
                <Heart stroke="white" />
              )
            ) : isFavourite ? (
              <Heart stroke="grey" />
            ) : hover && !loading ? (
              <Heart stroke="white" />
            ) : (
              <Heart stroke="white" fill="white" />
            )}
          </div>
        )}

        {loading && <SpinLoader />}
      </div>
    </div>
  )
}
