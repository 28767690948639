import type { Product } from '@commerce/types/product'
export default async function removeFromList(
  product: Product,
  email: string | null,
  listId: string,
  locale: string
) {
  const listItem = [product?.slug || product?.handle]
  let success: boolean = false
  try {
    const response = await fetch(`/api/list/${listId}/items`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, listItem, locale }),
    })
    if (response.status === 200) {
      success = true
    }
  } catch (err) {
    console.log(err)
  }
  return success
}
