const PlusWhite = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.5 4.5C12.5 4.22386 12.2761 4 12 4C11.7239 4 11.5 4.22386 11.5 4.5V11.5H4.5C4.22386 11.5 4 11.7239 4 12C4 12.2761 4.22386 12.5 4.5 12.5H11.5V19.5C11.5 19.7761 11.7239 20 12 20C12.2761 20 12.5 19.7761 12.5 19.5V12.5H19.5C19.7761 12.5 20 12.2761 20 12C20 11.7239 19.7761 11.5 19.5 11.5H12.5V4.5Z"
        fill="white"
      />
    </svg>
  )
}

export default PlusWhite
