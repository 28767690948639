import { useMutation, useQuery } from '@apollo/client'
import { getProduct } from '@commerce/api/operations'
import { formatPrice } from '@commerce/product/use-price'
import { Product } from '@commerce/types'
import {
  cartLinesAddMutation,
  cartLinesAddVars,
  getCartId,
  getCartQuery,
  getCurrencyCountryCode,
  getLanguage,
  normalizeCart,
} from '@commerce/utils'
import { Check, Plus, PlusWhite, SpinLoader } from '@components/icons'
import { useUI } from '@components/ui'
import {
  PARENT_PRODUCT_CUSTOM_ATTRIBUTE_KEY,
  RETAIL_PRICE_CUSTOM_ATTRIBUTE_KEY,
} from '@lib/helpers/general'
import useCustomer from '@lib/hooks/useCustomer'
import cn from 'clsx'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
interface Props {
  quickAddProductHandle: string
  hide?: boolean
  parentProduct?: {
    handle: string
    type: string | undefined
    colourCode: string | null | undefined
  }
  smallestCard?: boolean
}
const QuickAddButton = ({
  quickAddProductHandle,
  parentProduct,
  hide = true,
  smallestCard,
}: Props) => {
  const { t } = useTranslation(['common'])
  const { handle, type } = parentProduct!
  const [hover, setHover] = useState(false)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const { locale } = useRouter()
  const { openCartNotification } = useUI()
  const cartId = getCartId(locale)
  const { isB2B } = useCustomer()
  const { data } = useQuery(getCartQuery, {
    variables: {
      cartId,
      country: getCurrencyCountryCode(locale),
      language: getLanguage(locale),
    },
    context: { locale },
    skip: !cartId,
  })
  const cartItem = data?.cart ? normalizeCart(data.cart)?.lineItems : null
  let quantity = 1
  let colourCardProduct: Product.Product
  // const colourName = quickAddProductHandle?.split('-')?.[0]
  // const formattedName =
  //   colourName?.charAt(0)?.toUpperCase() + colourName?.slice(1)
  const isColourProduct =
    type?.toLowerCase() === 'colour' || type?.toLowerCase() === 'farbe'
  useEffect(() => {
    if (
      cartItem?.filter((item) => item.path === quickAddProductHandle).length! >
      0
    )
      setSuccess(true)
    else setSuccess(false)
  }, [cartItem])
  const fetchProduct = async () => {
    if (quickAddProductHandle) {
      const productPromise = getProduct(
        locale,
        quickAddProductHandle,
        'no-cache'
      )
      const { product }: { product: Product.Product } = await productPromise
      colourCardProduct = product
    }
  }
  const [addItem] = useMutation(cartLinesAddMutation, {
    onCompleted() {
      openCartNotification(
        `${quantity} ${t('colour-card')} ${t('added-to-cart')}`
      )
      setLoading(false)
      setSuccess(true)
    },
    onError() {
      setLoading(false)
    },
  })

  const addToCart = async () => {
    if (!success) {
      setLoading(true)
      await fetchProduct()
      const customAttributes = [
        {
          key: PARENT_PRODUCT_CUSTOM_ATTRIBUTE_KEY,
          value: handle,
        },
      ]
      const variant = colourCardProduct?.variants?.[0]
      if (isB2B) {
        // note: we are pushing the retail price in case of b2b
        // if that does not work, then we have to make two api calls one with
        // customer context and one without customer context
        customAttributes.push({
          key: RETAIL_PRICE_CUSTOM_ATTRIBUTE_KEY,
          value: formatPrice({
            amount: variant.price,
            currencyCode: colourCardProduct.price.currencyCode!,
            locale,
          }),
        })
      }

      const lineItemId = variant?.id?.toString()

      addItem({
        variables: cartLinesAddVars({
          locale,
          variantId: lineItemId,
          quantity,
          attributes: customAttributes,
        }),
        context: { locale },
      })
    }
  }

  const handleMouseOver = () => {
    setHover(true)
  }
  const handleMouseLeave = () => {
    setHover(false)
  }
  const handleClick = (e: any) => {
    e.preventDefault()
  }
  if (hide || !isColourProduct || !quickAddProductHandle || !parentProduct)
    return null
  return (
    <>
      <div
        onClick={handleClick}
        className="cursor-default"
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseLeave}
      >
        <div
          className={cn('flex  items-center justify-center  rounded-sm pl-3', {
            'bg-white opacity-75': hover && !smallestCard,
            'gap-2 py-1 pr-1': !success,
          })}
        >
          {hover && !smallestCard && (
            <div className="label text-dark-grey">
              {success
                ? `${t('colour-card-added')}`
                : `${t('add-colour-card')}`}
            </div>
          )}
          <div
            onClick={addToCart}
            className="cursor-pointer transition-colors duration-300 ease-in-out"
          >
            {loading && <SpinLoader />}
            {!loading && !success && (
              <div
                className={cn({
                  '-m-1 flex h-8 w-8 items-center justify-center rounded-full bg-white':
                    hover && smallestCard,
                })}
              >
                {hover ? <Plus /> : <PlusWhite />}
              </div>
            )}
            {success && (
              <div className="flex h-8 w-8 items-center justify-center rounded-full bg-white">
                <Check />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default QuickAddButton
